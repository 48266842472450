/* src/components/Homepage.css */

body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
}

.dropdown-menu:empty {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Common CSS
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outfit", sans-serif;
}

h1 {
  font-size: 45px;
  line-height: 50px;
  font-weight: 600;
}

.section-padding {
  padding: 80px 0;
}

p {
  color: #666666;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px;
}

h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
}

h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

h4 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}

// h5 {
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 25.2px;
//   letter-spacing: -0.486px;
// }

// h6 {
//   color: #ededed;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 29px;
// }

// Header

.header-btns li {
  list-style: none;
}

.btn-primary {
  border: 1px solid #3e549d !important;
  background: transparent !important;
  color: #3e549d !important;
  padding: 6px 20px;
  border-radius: 3px;
  font-size: 16px;
  // width: 125px;
  display: inline-block;
  text-align: center;
  transition: 0.3s;
}

.btn-primary:hover {
  background: #3e549d !important;
  color: #fff !important;
  border: 1px solid #3e549d !important;
}

.btn-secondary {
  background: #18191c;
  color: #fff;
  font-size: 16px;
  padding: 6px 20px;
  border-radius: 3px;
  // width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid transparent !important;
  transition: 0.3s;
}

.btn-secondary:hover {
  border: 1px solid #18191c !important;
  background: transparent !important;
  color: #18191c;
  transition: 0.3s;
}
.viewall-btn .btn-secondary:hover img {
  color: #18191c;
  filter: brightness(0.5);
}
.dark-header .navbar-brand img {
  width: 120px;
  cursor: pointer;
}

.dark-header {
  background: #f8f8f8;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #efefef;
}

// Banner
.banner-img img {
  width: 100%;
  max-width: 700px;
}

.banner-right-section h1 {
  color: #18191c;
  margin-bottom: 20px;
  width: 500px;
}

.banner-right-section p {
  color: #5e6670;
  margin: 0 0 20px 0;
  width: 500px;
}

.banner-section {
  // background: #f8f8f8;
  position: relative;
  overflow: hidden;
  padding: 100px 0 0 0;

  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.banner-img {
  position: absolute;
  left: 0;
}

.submit-btn {
  width: 15%;
}

.dark-btn {
  background: #3f559e;
  color: #fff;
  border: 1px solid #3f559e !important;
  width: 100%;
  padding: 6px 8px;
}

.home-searchbar .input-group-text {
  border: 0;
  background: transparent;
}

.home-searchbar {
  display: flex;
  box-shadow: 0px 11.098673820495605px 36.99557876586914px 0px #002c6d0a;
  border: 0.92px solid #e4e5e8;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}

.home-searchbar input {
  border: 0;
  background: transparent;
  width: 85%;
}

.home-searchbar input:focus {
  border: 0;
  outline: 0;
}

.banner-right-section {
  padding: 128px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.input-group {
  width: 88%;
}

.home-searchbar .input-group-text {
  width: 15%;
}

.border-btm {
  border-bottom: 1px solid #f1eaea !important;
}

// Logos Section
.logos-section {
  background: #3f559e;
  position: relative;
  overflow: hidden;
}

.logos-images ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logos-images ul li {
  list-style: none;
  width: 32%;
  padding: 20px 0;
}

.logos-images img {
  width: 100%;
  max-width: 190px;
}

.logos-left-sect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 500px;
}

.logos-left-sect h2 {
  font-family: Outfit;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 20px;
}

.logos-left-sect p {
  margin: 0;
  color: #fff;
}

.logos-section::before {
  content: url("../../public/assets/img/radial-stars.png");
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

// Video section
.common-title {
  text-align: center;
  max-width: 638px;
  margin: 0 auto 30px;
  text-transform: capitalize;
}
.common-title2 {
  max-width: 665px;
}
.video-sect-inner {
  position: relative;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-content p {
  margin: 40px 0 0 0;
  text-align: center;
}

.video-sect-inner img {
  width: 100%;
  border-radius: 20px;
}

// Jobs section
.title-head {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.viewall-btn .btn-secondary:hover {
  color: #18191c !important;
}

.viewall-btn .btn-secondary:hover img {
  color: #18191c;
}

.jobs-inner {
  border: 1px solid #e4e5e8;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
}

.job-load {
  display: flex;
}

.badge {
  background: #e7f6ea;
  color: #0ba02c;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px !important;
  width: fit-content;
  line-height: 20px !important;
}

.job-price {
  color: #767f8c;
  font-weight: 400;
  margin-left: 12px;
  font-size: 14px;
}

.google-icon {
  padding: 10px;
  background: #edeff5;
  width: 50px;
  animation-range: 50px;
  border-radius: 4px;
  text-align: center;
}

.job-location h6 {
  color: #18191c;
}

.google-search {
  display: flex;
}

.job-location {
  margin-left: 10px;
}

.job-location h6 {
  margin-bottom: 2px;
}

.job-location p {
  margin: 0;
  display: flex;
  align-items: center;
  color: #767f8c;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

.google-icon img {
  display: block;
  margin: 0 auto;
  text-align: center;
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.jobs-location-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobs-inner:hover {
  background: #fff6e6;
  cursor: pointer;
}

// Brokerage Section

.brokerage_secton {
  background: #dbe5ff;
}

.brokerage_details {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 500px;
  margin: 0;
}

.list_box {
  border-radius: 24px;
  padding: 18px;
  background: white;
  border: 1px solid #666666;
  cursor: pointer;
  margin: 8px 0;
  height: 300px;
}

.list_box img {
  background: #0155a5;
  padding: 10px;
  border-radius: 16px;
}

.option_bx {
  color: rgba(28, 47, 65, 1);

  font-weight: 600;
  margin-top: 12px;
}

.option_details {
  color: rgba(102, 102, 102, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list_box:hover {
  box-shadow: 35px 15px 45px 0px rgba(0, 0, 0, 0.09) !important;
  border: 1px solid transparent;
}

.space_gap {
  margin-top: 7px;
}

.brokerage_heading {
  width: 460px;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  margin: 0 0 20px 0;
}

// Statistics Section

.statistics-section {
  background: #f4f7ff;
}

.stats-inner {
  display: flex;
  justify-content: space-between;
  margin: 45px 0 0 0;
  padding: 0;
}

.stats-inner li {
  list-style: none;
  box-shadow: -13px 18px 35px 0px #00000017;
  padding: 40px;
  border-radius: 27px;
  width: 22%;
  height: 138px;
  text-align: center;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.stats-inner li h2 {
  color: #1c2f41;
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}

.stats-inner li h2 span {
  color: #2fb95d;
}

.stats-inner li p {
  margin: 0;
  display: none;
}

.stats-inner li:hover p {
  display: block;
}

.stats-inner li:hover {
  height: 160px;
  cursor: pointer;
}

// Testimonial Section
.testimonial-inner {
  position: relative;
  margin: 100px 0 0 0;
}

.testimonial-inner ul {
  padding: 0;
  margin: 0;
}

.testimonial-inner ul li {
  list-style: none;
}

.testimonial-content {
  box-shadow: -13px 18px 35px 0px #00000017;
  background: #fff;
  padding: 20px;
  border-radius: 16px;
}

.testimonial-person {
  display: flex;
  align-items: center;
}

.testimonial-person img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.testimonial-detail h6 {
  color: #1c2f41;
  margin: 0 0 2px 0;
}

.testimonial-detail p {
  margin: 0;
  color: #666666;
}

.testimonial-detail {
  margin: 0 0 0 15px;
}

.client-review p {
  color: #666666;
  font-weight: 400;
  line-height: 28px;
  margin: 17px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.blue-bg {
  background: #0155a5;
  width: 852px;
  height: 380px;
  display: block;
  position: absolute;
  z-index: -1;
  padding: 20px;
  top: -69px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 27px;
}

.testimonial-section {
  padding: 80px 0 160px 0;
}

// Faq Section

.faq-inner {
  box-shadow: 18px 15px 35px 0px #00000017;
  background: #fff;
  padding: 20px;
  border-radius: 23px;
}

.faq-section {
  background: #eaf0ff;
}

.faq-section .accordion-button {
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
}

.faq-section .accordion-button:focus {
  box-shadow: none;
}

.faq-section .accordion-button:not(.collapsed) {
  background: transparent;
  color: #1c2f41;
}

.faq-section .accordion-button::after {
  filter: sepia(1);
}

.accordion .accordion-item:last-child .accordion-button {
  border: 0;
}

// Contact Us section

.contactus-section {
  background: #eaf0ff;
}

.gradient-bg {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(219, 229, 255, 0) 79.37%
  );
  padding: 32px;
  border-radius: 30px;
}

.contact-us-left {
  background: #3f559e;
  padding: 25px;
  border-radius: 22px;
}

.form-group label {
  color: #1c2f41;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.form-group .form-control {
  border: 1px solid #666666;
  padding: 14px;
  border-radius: 25px;
}

.contact-us-form {
  background: #ffffff;
  padding: 25px;
  border-radius: 24px;
}

.form-view {
  margin: 50px 0 0 0;
}

.contact-us-left h4 {
  color: #fff;
}

.contact-us-left > p {
  color: #fff;
  margin: 0 0 27px 0;
}

.form-group {
  margin: 0 0 20px 0;
}

.get-started-btn .dark-btn {
  border-radius: 20px;
  padding: 12px 10px;
}

.get-started-btn .dark-btn:hover {
  border: 1px solid #3f559e;
  color: #3f559e;
  background: transparent;
}

/* footer   */
/* ======================================================================= */

.footer_section {
  background: rgba(29, 29, 29, 1);
}

.footer_details {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 32px;
  margin-top: 12px;
  max-width: 282px;
}

.footer_iconlist {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 12px;
  cursor: pointer;
  flex-wrap: wrap;
}

.img_icon {
  width: 20px;
  height: auto;
}

.footer_img {
  width: 100%;
  max-width: 40px;
  padding: 9px;
  height: 40px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 1);
  align-items: center;
}

.footer_img:hover {
  background: rgba(63, 85, 158, 1);
  border: 1px solid rgba(63, 85, 158, 1);
  cursor: pointer;
}

.footer_heading {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
}

.footer_items {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.footer_items a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  cursor: pointer;
}

.footer_list {
  display: flex;
  gap: 9px;
}

.footer_list .all_icon {
  width: 16px;
  height: 16px;
  object-fit: scale-down;
  margin-top: 4px;
}

.footer_list .list_name {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  line-height: 24px;
}

.enter_mail {
  height: 66px;
  padding: 24px 121px 24px 24px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}

.enter_mail::placeholder {
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  opacity: 50%;
}

.subscribe_btn {
  position: absolute;
  top: 0;
  right: -2px;
  background: rgba(63, 85, 158, 1);
  color: #fff;
  border: unset;
  padding: 21px;
  border-radius: 0px 16px 16px 0px;
  font-weight: 500;
  font-size: 16px;
}

.footer_space {
  margin-bottom: 20px;
}

// Jobs section

.categories-sidebar {
  // border: 1px solid #0000004f;
  border: 1px solid #e4e5e8;
  // border-radius: 47px;
  border-radius: 8px;
  padding: 25px;
}

.categories-sidebar ul {
  margin: 0;
  padding: 0;
}

.categories-sidebar li {
  list-style: none;
  margin: 0 0 5px 0;
  width: 49%;
}
.jobsdropdown {
  // border: 0.5px solid #0000004f;
  border: 1px solid #e4e5e8;
  // border-radius: 40px;
  border-radius: 8px;
  padding: 25px;
}

.job-searchbar {
  // border: 1px solid #0000004f;
  border: 1px solid #e4e5e8;
  padding: 30px 17px;
  // border-radius: 45px;
  border-radius: 8px;
}

.jobs-section {
  margin: 30px 0 0 0;
}

// .scrollbar-height {
//   height: 268px;
//   overflow-y: auto;
//   overflow-x: hidden;
//   padding: 0 10px 0 0;
// }

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #3c559b;
  border-radius: 5px;
}

// Css by Manjinder
// Profile page css
.portal-page {
  padding: 150px 0;
}

.profileImagemain {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.phoneInput_cls input.form-control {
  border: none !important;
  border-left: 1px solid #ced4da !important;
  border-radius: 0 !important;
}

.phoneInput_cls .form-control:focus {
  color: #212529;
  border-color: none;
  outline: 0;
  box-shadow: none !important;
}

.phoneInput_cls .selected-flag {
  border: none !important;
}

.btn-cancel {
  background: #3e549d;
  color: #fff;
  padding: 4px 20px;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid #3e549d;
}

.btn-cancel:hover {
  background: #fff;
  color: #3e549d;
  border: 1px solid #3e549d;
}

.btn-save {
  background: #fff;
  color: #3e549d;
  border: 1px solid #3e549d;
  padding: 9px 20px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.btn-save:hover {
  background: #3e549d;
  color: #fff !important;
  padding: 9px 20px;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-layout {
  background: #ffffff;
}
.box-shadow-prop > .row {
  box-shadow: 0 0 15px #3f576e4d;
  border-radius: 7px;
}
.profile-tabs-left {
  background: #ecf1f6;
  border-radius: 7px 0 0 7px;
  height: 100%;
  border-right: 1px solid #e9e7e7;
}
.profile-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2e2e2e;
  background-color: #ffffff;
  border-radius: 0 !important;

  text-align: left;
  font-size: 14px;
}
.profile-tabs .nav-link {
  color: #51617a;
  border-radius: 0 !important;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #e9e7e7;
  padding: 10px;
}
.profile-tabs .form-group {
  display: flex;
  margin: 0;
}
.profile-tabs label {
  width: 190px;
  // min-width: 160px;
  font-size: 14px;
  margin: 0;
}

.profile-tabs .profileImage-main {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
}
.profileImage-main {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-tabs .pprofile1 {
  border: 0;
}
.edit-profiles {
  background: #ccc !important;
  border-radius: 4px;
  border: 0 !important;
  padding: 4px;
  width: 32px;
  height: 30px;
  text-align: center;
}
.edit-profiles i {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.edit-profiles i:before {
  color: #fff;
}
.profile-tabs .form-group p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.logos-images {
  position: absolute;
  right: 0;
  width: 680px;
}
.logos-images img {
  width: auto;
  height: 35px;
}
.logo-end {
  margin-left: 140px !important;
}
.logo-end li {
  width: 24% !important;
}
// Jobs view css
.fa {
  cursor: pointer;
}
.cusrsor_pointer {
  cursor: pointer;
}
.main_container {
  // margin: 8rem 0px;
  margin: 8rem 0px 4rem; //gourav css
}
.header_nav {
  display: flex;
  gap: 5px;
  align-items: center;
}

.headerNav_child {
  color: #b9b9b9;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.headerNav_child_active {
  color: #18191c;
}

.headerNav_child::after {
  content: " >";
  position: relative;
  top: 1px;
}

.first_child_jobs {
  // margin-top: 50px;
  margin-top: 80px; //gourav css
  border: 1px solid #e4e5e8;
  border-radius: 8px;
  padding: 25px;
}

.viewjobs_header {
  color: #18191c;
  font-size: 22px;
  line-height: 20px;
}

.flag_ico {
  color: #808080ab;
  font-size: 12px;
}
.quentities {
  font-size: 13px;
  font-weight: 600;
  color: #0ba02c;
  background: #0ba02c17;
  padding: 4px 8px;
  border-radius: 3px;
  white-space: nowrap; //gourav css
}
.ranges {
  font-size: 13px;
  color: #767f8c;
  font-weight: 400;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.warehouse_desc {
  color: #000000;
  font-weight: 400;
  margin: 0px;
  font-size: 14px;
}
.warehouse_footer {
  color: #808080bd;
}
.warehouse_location {
  font-size: 14px;
  font-weight: 300;
}

.google_img {
  background: #d9d9d952;
  padding: 10px;
}
.google_location_parent {
  color: gray;
  font-weight: 400;
}
.response_parent {
  padding: 0 0 25px 0;
}
.warehouse_respons {
  margin-bottom: 0;
  color: #18191c;
  font-weight: 500;
  font-size: 24px;
}
.warehouse_desc2 {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  max-width: 685px;
}
.response_left {
  padding: 28px 0px;
  border: 1px solid #8080801c;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #8080800a;
}
.left_header {
  font-weight: 500;
  font-size: 16px;
  color: #18191c;
  margin-bottom: 15px;
}
.res_border {
  border-top: 1px solid #8080801a;
}
.fb_res {
  width: 162px;
}
.list_parent ::marker {
  font-size: 9px;
}
.ware_list {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.list_parent {
  max-height: 340px;
  overflow: auto;
}

// media queries
@media (max-width: 480px) {
  .headerNav_child {
    font-size: 13px;
  }
  .ware_header {
    font-size: 15px;
  }
  .first_child_jobs {
    margin-top: 28px;
  }
  .header_nav {
    flex-wrap: wrap;
  }
  .quentities {
    font-size: 12px;
    width: 130px;
  }
  .ranges {
    font-size: 12px;
  }
  .warehouse_desc {
    font-size: 10px;
    line-height: 20px;
  }
  .warehouse_buttons {
    flex-direction: column;
    width: 80%;
  }
  .warehouse_buttons button {
    font-size: 12px;
    padding: 8px;
  }
  .flex_google {
    margin: auto;
  }
  .ware_list {
    font-size: 14px;
    line-height: 20px;
  }
  .warehouse_desc2 {
    font-size: 14px;
    line-height: 20px;
  }
  .profile-tabs .form-group {
    flex-wrap: wrap;
  }
}
@media (max-width: 546px) {
  .warehouse_footer_child {
    flex-direction: column;
    justify-content: center;
  }
  .warehouse_footer {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .warehouse_location {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .wares_flex {
    flex-direction: column;
  }
  .flex_google {
    margin-top: 20px;
  }
}
@media (max-width: 1196px) {
  .left_header {
    font-size: 12px;
  }
  .header_nav {
    margin: 112px 0 0 0;
  }
}
// Loader css
div#loader {
  position: absolute;
  top: 50%;
  left: 48%;
  background: #8080803d;
  padding: 10px;
  border-radius: 12px;
}
img.loaderlogo {
  height: 80px;
}

// dashboard page css
input#pac_input_undefined {
  box-shadow: none;
}

.lds-ring {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// jobs page css
.job-searchbar input.form-control {
  padding-right: 35px;
}
.search_cross {
  position: relative;
  left: -34px;
  top: 14px;
  color: grey;
  font-size: 11px;
}
.headerNav_child_active::after {
  display: none;
}
.first_child_jobs .dark-btn-width {
  margin: unset;
}
.paginationWrapper.main-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination li {
  width: 40px;
  height: 38px;
  border: 0.37px solid #857979;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
}
.pagination li.active {
  background: #3f559e;
  border: 1px solid transparent;
}
.pagination li.active a {
  color: #fff;
}
li.back_page a {
  color: #000000;
  font-size: 14px;
}

form.centerLogin h3 {
  font-size: 24px;
  line-height: 21px;
  margin: 0 0 2px 0;
}
.right_side {
  background-color: #f8f8f8;
  height: 100%;
  padding: 20px 30px !important;
  border-radius: 20px;
  border: 1px solid #3f559e;
}
form.centerLogin p.accopunt {
  font-size: 15px;
}
form.centerLogin label.form-label.ml-2 {
  font-size: 14px;
  margin: 0 0 2px 0;
}
::-webkit-scrollbar {
  width: 3px !important;
}
.bginput.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #47519e !important;
}
.RSPBstep .activebar {
  background: #3c559b;
  padding: 8px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 40px;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
}
.RSPBstep .non-activebar {
  background: #f2f2f2;
  padding: 8px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 40px;
  height: 40px;
  color: #3c559b;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef;
}
.RSPBprogressBar {
  height: 6px !important;
  width: 300px;
  margin: 0 auto;
}
.progressbar-num {
  margin: 9px 0;
}
.width-set {
  width: 100px;
}
.job-detail h4 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 19px;
}
.faq-inner .accordion-item {
  border: 0;
  margin: 0px 0px 2px 0px;
}
.edit-section-img .profileImage {
  width: 72px !important;
  height: 72px !important;
  object-fit: cover;
}
.profile-tabs .edit-section-img .new_images {
  width: unset;
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
.edit-section-img .profile_img_side {
  position: relative;
  text-align: center;
  width: 72px;
  height: 72px;
  margin: 0 auto;
}
.edit-section-img span.uploader {
  margin: 0;
  background-color: #fff;
  padding: 3px;
  border-radius: 40px;
  box-shadow: 0px 0px 4px 0px #47569d;
}
.form-control::placeholder {
  font-size: 14px;
}
.form-control:focus {
  box-shadow: none;
}
.edit-section-img input {
  font-size: 14px !important;
}
.edit-section-img label {
  font-size: 13px;
  letter-spacing: 0.45px;
  color: #444;
}
.edit-section-img .width-set {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edit-section-img select {
  font-size: 12px;
}
img.pageLoader {
  width: 38px;
}
.first_dropdown .btn {
  box-shadow: none;
}
.first_dropdown .dropdown-toggle::after {
  display: none;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.categories-sidebar h4 {
  font-size: 16px;
  letter-spacing: 0.64px;
}
.categories-sidebar ul {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.categories-sidebar .form-check {
  font-size: 13px;
  color: #7a7a7a;
}
.portal-tabs {
  background: #fff;
  border: 1px solid #cbc1c1;
  border-radius: 4px;
}

.portal-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #494f9f;
  border-color: unset;
  border-radius: 4px;
  border: 0;
  margin: 2px;
}
.portal-tabs .nav-tabs .nav-link {
  border-radius: 4px;
  color: #000;
  background: #fff;
  display: flex;
  align-items: center;
  margin: 2px;
}
.table th {
  font-weight: 500;
  white-space: nowrap;
}
.c-dropdown i {
  font-size: 12px;
  margin: 0 6px 0 0 !important;
}
.delete-truck {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete-truck i {
  font-size: 12px;
  padding: 6px 12px;
  border: 1px solid #ced4da;
  height: 100%;
  border-radius: 4px;
  background: #f2f2f2;
  color: #c33737 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paginationWrapper.main-pagination select {
  text-align: center;
  width: 44px;
  padding: 0.375rem 0.3rem !important;
}
.pagination {
  margin: 0;
}
.select-board select {
  height: 44px;
  font-size: 14px;
  color: #837979 !important;
  appearance: auto;
}
.dropdown-name b {
  font-size: 17px;
  font-weight: 500;
  margin: 0 0 2px 0;
  display: block;
}
.dropdown .dropdown-name {
  margin: 0 4px !important;
}

.delete-btn-red i {
  font-size: 13px;
  padding: 12px;
  background: #f2f2f2;
  border: 1px solid #efefef;
  border-radius: 4px;
  color: #bd3131 !important;
}
.job-searchbar {
  border: 1px solid #e4e5e8;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
}
.tabs-view ul.nav.nav-tabs {
  display: flex;
  justify-content: end;
  border: 0;
  background: #efefef;
  border-radius: 4px;
}
.tabs-view ul.nav.nav-tabs li {
  // display: flex;
  // justify-content: end;
  // background: #efefef;
  // cursor: pointer;
  // height: 40px;
  width: 50%;
  border-radius: 4px;
}
.tabs-view ul.nav.nav-tabs li a {
  text-decoration: none;
  border: 0 !important;
  height: 38px;
  text-align: center;
  border-radius: 4px;
  margin: 2px;
}
.badge.badge-success.common-badge {
  display: block;
  width: 61px;
  padding: 5px 9px;
  background: #494f9f;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}
// .job-sect-table table {
//   width: 1400px;
// }
.load-id {
  font-size: 13px;
  cursor: pointer;
}
.job-searchbar h4 {
  font-size: 18px;
  line-height: 20px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 4px !important;
}
.ellipses {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
.pprofile1.edit-page-common {
  background: transparent;
  padding: 0;
}
// .edit-page-common .title-head {
//   background: #dedddd;
//   padding: 21px 30px;
//   border-bottom: 1px solid #cbcbcbef;
//   border-top-left-radius: 12px;
//   border-top-right-radius: 12px;
// }
.white-bg-main {
  background: #fff;

  border: 1px solid #efefef;
  border-radius: 5px;
}
.white-head {
  background: #e0e0e0;
  padding: 15px 20px;
  border-bottom: 1px solid #efefef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.white-head h5 {
  margin: 0;
}
.white-bg-main > .row {
  padding: 15px 20px;
}
.badge.badge-success.common-badge {
  display: block;
  width: 60px;
  padding: 5px 9px;
  background: #494f9f;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}

.viewUsers {
  color: #202020;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
}
.common-padding {
  padding: 30px;
}
.col-md-6.mb-3.view-flex,
.col-md-12.mb-3.view-flex {
  display: flex !important;
}

.view-flex .profileheddingcls {
  width: 175px;
}
.view-flex .profiledetailscls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  height: 40px;
  font-size: 14px;
  color: #000;
  font-weight: 400 !important;
}
.col-md-6.mb-3 {
  display: unset !important;
}
.main-row.common-padding label {
  margin-bottom: 5px;
}
.range_numbers {
  margin: 12px 0 0 0;
}
.expired-job {
  display: flex;
  align-items: center;
  background: #9d1414;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  height: 30px;
}
.expired-job svg {
  margin-right: 5px;
  font-size: 14px;
}
.expired-job span {
  font-size: 14px;
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  background: #fff;
  padding: 20px;
}
.no-data img {
  width: 130px;
}
.job-loc-text {
  width: 250px;
}
.custom-exclamation {
  font-size: 56px;
  padding: 40px;
  background: #494f9f;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  color: #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
span.custom-toggle-btn {
  height: 100%;
  display: flex;
}
.active .custom-toggle-btn .react-switch-bg {
  height: 20px !important;
  width: 40px !important;
  background: #494f9f !important;
}

// .react-switch-handle {
//   width: 12px !important;
//   height: 12px !important;
//   top: 4px !important;
//   transform: translateX(4px) !important;
// }
.custom-toggle-btn .react-switch-bg svg {
  display: none;
}
.main-head .dropdown-toggle::after {
  display: none;
}
.username-head {
  text-align: right;
}
.username-head b {
  font-size: 14px;
  font-weight: 500;
}
.username-head p {
  font-size: 12px;
  margin: 0;
  line-height: 15px;
  color: #746c6c;
  letter-spacing: 0.53px;
}
.profile-dropDOwn .dropdown-item svg {
  font-size: 14px;
}
.profile-dropDOwn .dropdown-item {
  font-size: 13px;
  color: #000;
  display: flex;
  align-items: center;
}
.hours-left {
  background: #3b549a;
  color: #fff;
  padding: 3px 10px;
  border-radius: 7px;
  font-size: 13px;
  height: 30px;
}
.bid-count {
  cursor: pointer;
  color: #3b549a;
}

//  Contact us page
.page_wrapper {
  margin: 80px 0;
}
.contact_hero {
  background-image: url("../../public/assets/img/contact_hero.png");
  height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
}
.contact_hero_parent {
  padding-left: 155px;
  padding-top: 138px;
}
.contact_btn {
  background: #3f559e;
  color: #fff;
  outline: none;
  border: none;
  padding: 5px 10px;
  font-weight: 400;
}
.contact_heading {
  margin-top: 15px;
  color: #fff;
  font-size: 60px;
  max-width: 601px;
  line-height: 65px;
  text-shadow: 1px 2px black;
}

.support_row {
  margin-top: 70px;
}
.support_col {
  margin-top: 110px;
}
.support_heading {
  color: #111827;
  font-size: 40px;
  margin-bottom: 15px;
}
.support_child {
  // color: #111827;
  // font-size: 35px;
  // font-weight: 200;
  // margin-bottom: 22px;
  color: #111827;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px;

}
.support_desc {
  margin-top: 15px;
  color: #000000;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  padding-right: 60px;
  text-align: justify;
}
.support_card {
  background: #819eff17;
  padding: 35px 30px;
  border-radius: 30px;
  position: sticky;
  top: 120px;
}
.card_heading_section {
  width: 155px;
}
.Card_heading {
  color: #111827;
  font-size: 27px;
  margin: 0;
}
.card_desc {
  color: #111827;
  font-weight: 300;
  font-size: 18px;
}
.support_card input {
  border: none;
  margin-top: 5px;
  border-radius: 10px;
}
.support_card .dark-btn {
  width: 135px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-top: 20px;
}
.assistance_card {
  box-shadow: 1px 4px 12px 0px #00000040;
  padding: 40px;
}
.as_head {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  white-space: nowrap;
}
.as_desc {
  color: #828282;
  font-weight: 300;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.assistance_card .dark-btn {
  margin-top: 10px;
  border-radius: 10px;
  width: 160px;
  font-size: 14px;
}
.growth_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.growth_header {
  color: #000000;
  font-size: 50px;
  margin-bottom: 30px;
}
.growth_desc {
  color: #000000;
  font-size: 23px;
  font-weight: 400;
}
.growth_btns {
  margin-top: 12px;
  gap: 17px;
}
.growth_btns button {
  width: 135px;
  border-radius: 50px;
  height: 42px;
}
.growth_btns .btn-primary {
  border-color: #000 !important;
  color: #000 !important;
}
.growth_btns .btn-primary:hover {
  border-color: #fff !important;
  color: #fff !important;
}

// About page
.about_hero {
  background-image: url("../../public/assets/img/about-hero.png");
  height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
}
h4.about_header {
  color: #ffffff;
  font-weight: 400;
  font-size: 60px;
}
.about_div {
  margin-top: 70px;
}
.story_header {
  color: #111827;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 25px;
}
.story_desc {
  color: #000000;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 45px;
}
.hero_section {
  position: relative;
}
.about2 {
  position: absolute;
  top: 75px;
  right: 194px;
}
.best_images {
  height: 400px;
  object-fit: cover;
}
.why_use {
  color: #3f559e;
  font-weight: 400;
  margin-top: 20px;
}
.choose_bar {
  color: #195a00;
  width: 50px;
  height: 2px;
  background: #195a00;
  display: inline-block;
  position: relative;
  top: -3px;
  right: -6px;
}
.best_right {
  font-weight: 700;
  color: #333333;
  font-size: 30px;
}
.right_desc {
  font-weight: 400;
  color: #828282;
  font-size: 13px;
  line-height: 22px;
}
.best_cards {
  box-shadow: 0px 0px 77.40259552001953px 0px #cdcdcd40;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}
.cards_title {
  color: #4f4f4f;
  margin-left: 15px;
  font-size: 12px;
}

.growth_header {
  color: #000000;
  font-size: 50px;
  margin-bottom: 30px;
}
.growth_desc {
  color: #000000;
  font-size: 23px;
  font-weight: 400;
}
.growth_btns {
  margin-top: 12px;
  gap: 17px;
}
.growth_btns button {
  width: 135px;
  border-radius: 50px;
  height: 42px;
}
.growth_btns .btn-primary {
  border-color: #000 !important;
  color: #000 !important;
}
.growth_btns .btn-primary:hover {
  border-color: #fff !important;
  color: #fff !important;
}

// About page
.about_hero {
  background-image: url("../../public/assets/img/about-hero.png");
  height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
}
.about_div .btn {
  font-weight: 500;
}
h4.about_header {
  color: #ffffff;
  font-weight: 400;
  font-size: 60px;
}
.about_div {
  margin-top: 140px;
}
.story_header {
  color: #111827;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 25px;
}
.story_desc {
  color: #000000;
  font-weight: 300;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 45px;
}
.hero_section {
  position: relative;
}
// .about2 {
//   position: relative;
//   top: -362px;
//   left: 253px;
// }
.aboutImg {
  position: absolute;
  top: 80px;
  right: 135px;
}
.best_images {
  height: 520px;
  object-fit: contain !important;
  // width: 395px !important;
}
.why_use {
  color: #3f559e;
  font-weight: 400;
  margin-top: 25px;
}
.choose_bar {
  color: #195a00;
  width: 50px;
  height: 2px;
  background: #195a00;
  display: inline-block;
  position: relative;
  top: -3px;
  right: -6px;
}
.best_right {
  font-weight: 700;
  color: #333333;
  font-size: 30px;
}
.right_desc {
  font-weight: 400;
  color: #828282;
  font-size: 13px;
  line-height: 22px;
}
.best_cards {
  box-shadow: 0px 0px 77.40259552001953px 0px #cdcdcd40;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border-left: 3px solid #80808000;
}
.best_cards:hover {
  border-left: 3px solid #3f559e;
}
.cards_title {
  color: #4f4f4f;
  margin-left: 12px;
  font-size: 12px;
}
.short_cards {
  height: 40px;
}
.management_flex {
  justify-content: space-between;
}
.management_1 {
  width: 620px;
}
.trending_col .story_desc {
  text-align: justify;
  padding-right: 120px;
}
.manage_flex_child {
  display: flex;
  gap: 20px;
}
.manage_child_flex {
  gap: 20px;
}
.m_images {
  border-radius: 10px;
  width: 285px;
}

// Grow section
.grow_parent {
  background-image: url(../../public/assets/img/grow-bg.png);
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.grow_parent .btn {
  font-weight: 500;
}
.grow_desc {
  color: #232323;
  font-weight: 400;
  font-size: 25px;
}
.grow-header {
  color: #232323;
  font-weight: 600;
  font-size: 55px;
  margin-top: 20px;
  // background: linear-gradient(180deg,rgba(0, 0, 0) 0%,rgba(0, 10, 0) 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // -webkit-text-stroke: 4px #fff;
}

// signup css
.input_div {
  background: #fff;
  padding: 8px 15px;
  border-radius: 4px !important;
  border: 1px solid #e8e8e8;
  height: 39px;
}

.rejected span {
  background: #b81616;
  padding: 4px 10px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  font-size: 13px;
}
.accepted span {
  background: #3c559b;
  padding: 4px 10px;
  border-radius: 3px;
  color: #fff;
  text-transform: capitalize;
  font-size: 13px;
}
.pending span {
  background: #efefef;
  padding: 4px 10px;
  border-radius: 3px;
  color: #000;
  text-transform: capitalize;
  font-size: 13px;
}
.address-head h2 {
  background: #fff0f0;
  font-size: 18px;
  padding: 2px 15px;
  line-height: 35px;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #efefef;
  background: #ededed;
}
.portal-tabs button {
  display: flex;
  align-items: center;
}

// .jobs-modal.modal.fade:not(.in).right .modal-dialog {
// 	-webkit-transform: translate3d(25%, 0, 0);
// 	transform: translate3d(25%, 0, 0);
// }
// .jobs-modal.modal.fade:not(.in).bottom .modal-dialog {
// 	-webkit-transform: translate3d(0, 25%, 0);
// 	transform: translate3d(0, 25%, 0);
// }

// .jobs-modal.modal.right .modal-dialog {
// 	position:absolute;
// 	top:0;
// 	right:0;
// 	margin:0;
// }

// .jobs-modal.modal.right .modal-content {
// 	min-height:100vh;
// 	border:0;
// }

// .modal.fade:not(.in).left .modal-dialog {
// 	-webkit-transform: translate3d(-25%, 0, 0);
// 	transform: translate3d(-25%, 0, 0);
// }
// .modal.fade:not(.in).right .modal-dialog {
// 	-webkit-transform: translate3d(25%, 0, 0);
// 	transform: translate3d(25%, 0, 0);
// }
// .modal.fade:not(.in).bottom .modal-dialog {
// 	-webkit-transform: translate3d(0, 25%, 0);
// 	transform: translate3d(0, 25%, 0);
// }

// .modal.right .modal-dialog {
// 	position:absolute;
// 	top:0;
// 	right:116px;
// 	margin:0;
//   width: 100%;
// }

// .modal.left .modal-dialog {
// 	position:absolute;
// 	top:0;
// 	left:0;
// 	margin:0;
//   width: 100%;
// }

// .modal.left .modal-dialog.modal-sm {
// 	max-width:300px;
// }

// .modal.left .modal-content, .modal.right .modal-content {
// 	min-height:100vh;
// 	border:0;
// }

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
  padding-bottom: 10px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.in .modal-dialog {
  right: 0;
}

.badges-main ul {
  display: flex;
  padding: 0;
}
.badges-main li {
  margin: 0 4px;
}
.badges-main li {
  position: relative;
  display: flex;
  align-items: center;
}
.badges-main {
  margin-top: 10px;
}
.badges-main span {
  margin-left: 4px;
}
// .badges-main span {
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: #000;
//   padding: 2px;
//   border-radius: 50%;
//   width: 20px;
//   height: 20px;
// }
.ViewUseraa {
  color: #202020;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
}
.imagethumbWrapper img {
  width: 100px;
  border-radius: 50px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #e3dada;
}
.col-md-4.view-flex {
  display: flex;
}
.edit-bg i {
  background: #ccc;
  padding: 8px 9px;
  border-radius: 4px;
  color: #ffffff !important;
}

.job-load.job-time-absolute {
  position: absolute;
  right: -13px;
  top: -13px;
  background: #3b549a !important;
  padding: 2px 10px;
  border-radius: 4px;
}
.job-load.job-time-absolute .badge {
  background: transparent;
  margin: 0;
  color: #fff;
}
.job-load.job-time-absolute p {
  margin: 0;
  padding: 0;
  color: #fff;
}
.portal-tabs-view li {
  width: 50%;
  text-align: center;
}

input#statusDropdown_input {
  width: 100px !important;
}
.upload-img-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.upload-img-icon .btn {
  margin: 0;
  background: #efefef;
  padding: 2px;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-img-icon svg {
  color: #969595;
}
.imagethumbWrapper .upload-img-icon .fa-times {
  position: unset;
  background-color: transparent;
  color: #969595;
}
.btn-end-save {
  margin: 0 0 0 auto;
}
p.job-loc-text img {
  width: 15px;
}
.profiledetailscls.ml-2.board-name {
  background: #efefef;
  padding: 3px 8px;
  margin: 0 4px 0 0;
  display: inline-block;
  height: 26px;
  border-radius: 3px;
  font-weight: 500;
}
.timer {
  background: #eaeaea;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  color: #302e2e;
  width: 142px;
}
.jobs-modal .badges-main li {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: capitalize !important;
  font-weight: 500;
  background: #efefef;
  // padding: 2px 4px;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
}
.jobs-modal .badges-main li .badge {
  text-transform: capitalize !important;
  font-size: 14px;
}
.jobs-modal .modal-dialog {
  width: 400px !important;
}
textarea.form-control {
  resize: none;
}
span.see-more {
  font-size: 12px;
  line-height: 22px !important;
  color: #3c559b;
  text-align: end;
  height: 14px !important;
  cursor: pointer;
  display: inline;
}

.read-more-new.profiledetailscls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  height: auto;
  border: 0;

}
.load-modal-detail {
  background: #f3f3f3;
  padding: 10px;
  margin: 0 0 20px 0;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.load-modal-detail-inner {
  display: flex;

  justify-content: space-between;
}
.load-modal-detail-inner p {
  margin: 0;
}
.load-modal-detail-inner p {
  margin: 0;
  font-size: 15px;
}

.profile-dropDOwn .dropdown-menu {
  z-index: 999999 !important;
}

.pending span {
  background: #efefef;
  padding: 2px 10px;
  border-radius: 4px;
}

.rdrStartEdge.rdrEndEdge {
  background: #494f9f;
}

#headlessui-menu-button-1 i {
  margin: 0 0 0 6px;
  font-size: 14px;
}

#headlessui-menu-button-1 {
  background: #494f9f !important;
  color: #fff !important;
}

span.arrowss {
  color: #494f9f !important;
  height: unset;
  margin: 0 4px !important;
  width: unset !important;
  position: unset !important;
}

.legends .color {
  background: #494f9f !important;
}

.rdrStartEdge.rdrEndEdge {
  background: #494f9f;
}

#headlessui-menu-button-1 i {
  margin: 0 0 0 6px;
  font-size: 14px;
}

#headlessui-menu-button-1 {
  background: #494f9f !important;
  color: #fff;
}

span.arrowss {
  color: #494f9f !important;
  height: unset;
  margin: 0 4px !important;
  width: unset !important;
  position: unset !important;
}

.legends .color {
  background: #494f9f !important;
}

.modal-body .small {
  margin-bottom: 20px;
  background: #efefef;
  padding: 10px;
  border-radius: 4px;
}

.rdrDayNumber span:focus-visible {
  border: 0;
  outline: 0 !important;
}

.rdrStartEdge {
  background: #494f9f !important;
}

.rdrInRange {
  background: #494f9f !important;
}

.bid-submission .modal-body .small {
  margin-bottom: 20px;
  background: #efefef;
  padding: 4px 10px;
  border-radius: 4px;
}
.rdrDayNumber::after {
  text-decoration: none;
}
span.rdrEndEdge {
  background: #474e9c;
}
p.view_graph {
  display: flex;
}
.rdrDayToday .rdrDayNumber span:after {
  display: none;
}
.btn-primary:focus {
  background-color: #474e9c;
  border-color: #474e9c;
  box-shadow: none;
}
.swal2-container img {
  width: 100px;
  margin: 20px auto 0;
}
h2#swal2-title {
  padding: 0;
}
button.swal2-confirm.swal2-styled {
  background: #3c559b;
}
div#swal2-html-container {
  margin: 0;
}
.expired {
  background: #ac2222;
  color: #fff;
  text-align: left;
  width: fit-content;
  display: flex;
  align-items: center;
}
.expired.timer.expiredfull {
  background: transparent;
  width: unset;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
}
.error-show.col-md-6.mb-3 {
  margin: 0 !important;
}
.iframe_div iframe {
  width: 100%;
}
.button_row {
  text-align: -webkit-right;
}
.delete_icon {
  /* border: 1px solid #000; */
  box-shadow: 0px 0px 10px -5px #000;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ed0a0a;
}
.documentation_modal {
  max-height: 394px !important;
  overflow: auto;
}
.tableficon i {
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: gray;
  font-size: 10px;
  border: 1px solid #80808029;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heightbtn .react-switch-bg {
  height: 20px !important;
  width: 40px !important;
}
.rounded-circle {
  width: 34px !important ;
  height: 34px !important;
  object-fit: cover;
}
.nowrapflex {
  flex-wrap: nowrap !important;
}
.bgtrans {
  background: transparent;

  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
.bgback {
  background: #f8f8f8;
}
@media (max-width: 991px) {
  .bgtrans {
    background: #f8f8f8;
  }
}
.zfonts {
  font-size: 8px;
  padding-left: 3px;
  line-height: 13px;
}
.afont {
  position: relative;
  top: 0px;
  margin-top: -5px;
}
.zfont {
  // position: relative;
  bottom: -5px;
}
.arrfont {
  font-size: 15px;
  color: #000000c4;
  height: 19px;
  line-height: 19px;
  width: 15px;
  margin-left: -1px;
}
.notfounddata {
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 30px;
}
input.keysearch {
  font-size: 14px;
}
nav.navfix {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}
.socrollingcol {
  position: fixed;
  max-height: calc(100vh - 71px);
  overflow: auto;
  overflow-x: hidden;
  padding: 10px 0px;
}
.socrollingcol span{
  font-size: 13px !important;
}
.dotsbtn .dropdown-toggle::after {
  display: none !important;
}
// .dotsbtn .btn {
//   color: gray !important;
//   padding: 0;
//   // height: 26px;
//   width: 27px;
//   line-height: 0px;
//   border-radius: 5px;
//   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
//   font-size: 15px;
//   border: 1px solid #80808029 !important;
// }
.dotsbtn .btn {
  color: #fff;
  padding: 0;
  width: 27px;
  line-height: 0px;
  border-radius: 2px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  font-size: 15px;
  /* border: 1px solid #80808029 !important; */
  // background: #42529e !important;
  background: #808080b0 !important;
  margin-right: 12px;
}
.dotsbtn .btn:focus {
  box-shadow: none;
}
.dotdiv {
  position: relative;
}
.destspan {
  display: inline-block;
  min-width: 138px;
}
.dotsbtn2 .dropdown-toggle::after {
  display: none !important;
}
.dotsbtn2 .btn:focus {
  box-shadow: none;
}
.dotsbtn2 .btn {
  color: #fff;
  padding: 0;
  width: 27px;
  line-height: 0px;
  border-radius: 2px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  font-size: 15px;
  /* border: 1px solid #80808029 !important; */
  // background: #42529e !important;
  background: #808080b0 !important;
}
.dropdown.dotsbtn {
  position: absolute;
  top: -13px;
  right: 0;
}
.updatestus {
  background: #3f559e;
  color: #fff;
  border: 1px solid #3f559e !important;
  padding: 6px 8px;
  font-size: 12px;
}
.updatestus:hover {
  background: #3f559e;
  color: #fff;
  border: 1px solid #3f559e !important;
  padding: 6px 8px;
  font-size: 12px;
}
.pdfimg {
  width: 50px;
  object-fit: cover;
}
.pdffdiv {
  border: 1px solid #80808036;
  padding: 10px;
}
.uploadload {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #41519d;
  width: 35px;
  height: 35px;
  animation: spin 2s linear infinite;
  margin: auto;
  position: absolute;
  top:50%;
  left: 50%;
}
.uploadload {
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.pickdate .react-datepicker-wrapper {
  width: 100% !important;
}
.phonenoti {
  display: none;
}
@media (max-width: 991px) {
  .phonenoti {
    display: block;
  }
  .desknoti {
    display: none !important;
  }
}
// services css
.about_ser {
  background-image: url("../../public/assets/img/about-hero.png");
  background-image: url("../../public/assets/img/ser.jpg");
  // height: 480px;
  height: 740px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}
.servicespera1 {
  font-size: 18px;
}
.serivcesh{
  font-size: 25px;
  margin-bottom: 10px;
}
.serivcesp{
  text-align: justify;
}
.serviceimges {
  height: 300px;
  object-fit: cover;
  width: 90%;
  border-radius: 20px;
}
.about_ser::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#000000,#626060);
  opacity: .6;
}
.serhero{
  background-color: #000;
    height: 665px;
    position: relative;
}
.ser_img {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  // mix-blend-mode: luminosity;
  opacity: 0.5;
  object-fit: cover;
  width: 100%;
}
.about_parentt {
  // border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 45%;
  width: 100px;
}
.about_headingg {
  color: #fff;
  font-size: 52px;
  font-weight: 600;
  white-space: nowrap;
}

// Support
.suppimges {
  width: 190px;
  height: 160px;
  // object-fit: cover;
}
.trackthree{
  font-size: 19px;
    font-weight: 500;
    color: #42529e;
}
.serivceshh {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}
.story_descs {
  color: #000000;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 45px;
  margin-top: 2px;
}



.suppimgess {
  width: 40px;
  height: 40px;
}
.serivceshhh{
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 10px;
}
.serivcespp{
  margin-bottom: 0;
}

// about us
.soloflex{
  display: flex;
  align-items: center;
}
.serivcesps{
  margin: 0;
}
.calldiv{
  background: #8080803b;
  padding: 10px 60px;
}
.callflex svg{
  font-size: 23px;

}
.callme{
  width: 270px;
  object-fit: cover;
}
.dropspans{
  display: block;
  margin: 5px 10px;
  padding: 5px 10px;
  font-size: 13px;
}
.dropspdiv{
  padding-bottom: 5px;
  padding-top: 5px;
  background: #fff;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
    width: 258px;
    min-height: 35px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

}
.dropspans:hover{
  background: #80808030;
  border-radius: 5px;

}
.badgeflex {
  display: flex;
  gap: 6px 0px;
  flex-wrap: wrap;
  max-height: 130px;
  overflow: auto;
  padding: 10px 0px;
}
.badgeflex li div{
  font-size: 12px;
  padding: 5px 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filterbt:focus{
  background: #42529e !important;
  box-shadow: none !important;
  color: #fff !important;
}
.filterbt:active{
  background: #42529e !important;
  box-shadow: none !important;
}
.statusdivs .delivered {
  color: #3a7c3a !important;
}
.statusdivs .pending {
  color: #b81616d9;
}
.statusdivs .in_transit {
  color: #06065e;
}
.statusdivs .pickedup {
  color: #181856;
}
.statusdivs .awarded{
  color: #4a4c9f !important;
  font-size: 12px;
  font-weight: 500;
}
.statusdivs .accepted{
  color: green !important;
  font-size: 12px;
  font-weight: 500;
}
.clico{
  color: #3e549d;
}
.aginposition{
  position: absolute;
  right: 0;
  top: -15px;
}

.acttwo{
  position:  relative !important;
  margin-left: 0 !important;
}
.acttwo.dotsbtn {
  top: 0;
}
.acttwo.dotsbtn .btn{

    color: #000;
 padding: 0;
 width: auto;
    line-height: 0px;

box-shadow: none;
    font-size: 18px;
 border: none!important;
    background: none !important;

}
.acttwo .dropdown-menu {

  min-width: 6rem;

}
.actiondbtn {
  background: none;
  border: none;
  color: gray;
}
.actiondbtn::after {
display: none !important;
}
.dotsmore {
  font-size: 20px !important;
}
.actiondbtnparent{
  position: relative;
}
.actiondbtnparent ul.dropdown-menu.show {
  min-width: 80px;
  padding: 3px 5px !important;
  position: absolute !important;
  left: -15px !important;
  top: -10px !important;
}
.actiondbtnparent a{
  padding: 3px 3px !important;
}
.actiondbtnparent span{
  font-size: 13px;
}
.sidees{
  font-size: 13px;
}
.respTable{
  width: 100%;
  overflow-x: auto;
}

// New
.spaceBadge{
  display: inline-block !important;
  margin-top: 5px !important;
}
.abc img{
  width: 50px;
  height: 50px;
}
.borderUnset{
  border-radius: 0px !important;
  width: auto !important;
  height: 100px !important;
  border: none !important;
  margin-bottom: 15px;
}
.widthitbox{
    display: block;
    margin: 0 auto 20px auto;
}
.dropsdivTwo{
  top: 36px ;
}
// Badges
.accepted_badgeNew {
  background: #0ba02c1f;
  color: #0ba02c;
}
.rejected_badgeNew {
  background: #ff000038 !important;
  color: #d50202 !important;
}
.pending_badgeNew{
  background: #474e9c1c ;
  color: #0000006e  ;
}

.awarded_badgeNew{
  color: #4a4c9f !important;
  background: #4a4c9f3d !important;
}
.delivered_badgeNew{
  background: #e7f6ea !important;
  color: #0ba02c !important;

}
.tophandle{
  margin-top: 120px !important;

}
// icons color

  .editButton{
    color: #5146a0 !important;
  }
  .deleteButton{
color : #e60303 !important;
  }

  // .checkListImg{
  //   height: 100px;
  //   width: 100px;

  // }
  .checkListImg {
    height: 25px;
    width: 25px;
}
.borderFor{
  border: 1px solid #8080802e;
}
.licenceimgTwoProd{
  height: auto !important;
}
.checklistFirstFlex {
  padding: 10px 0px;
  gap: 5px;
  background: #494f9f14;
  border-bottom: 1px solid #0000001c;
}
.paddningMod {
  margin-left: 8px;
  padding-left: 2px;
  border-radius: 66px;
  padding: 5px;
  background: #fff;
}
.whiteit{
  white-space: nowrap;
}
  // pagination
  .between_pagination{
    justify-content: space-between !important;
  }
@media(max-width:1199px){
  .borderUnset{
    height: 170px !important;
    width: 245px !important;
    object-fit: contain;
  }
}
@media(max-width:480px){
  .borderUnset {
     height: auto !important;
    width: 130px !important;
}
}
.loadCross .search_cross1 {
  position: absolute !important;
  top: 14px;
  color: grey;
  font-size: 11px;
  right: 10px !important;
}
.licenceimgTwo{
  width: 60px !important;
  height: 60px !important;
  object-fit: cover !important;
  border-radius: 3px !important;
  margin: 0 !important;
}
.lis_times {
  top: -9px !important;
  left: 49px !important;
}
.addressInput :nth-child(3){
  width: 100% !important;
}
.addressWidth{
  min-width: 125px;
}
.addressDetails{
  height: auto !important;
}
@media(max-width:480px){
  .addressWidth{
    min-width:90px;
  }
}
// serchbar
.searchParent {
  position: relative;
}
.searchParent .material-icons {
  position: absolute;
  top: 20px;
  right: 28px;
  background: none;
  margin: 0;
  height: 0;
  padding: 0;
  line-height: 0;
  width: 0;
  color: #0000006e;
  font-size: 18px;
}
.searchParent .form-control {
  padding-right: 28px;
}
.modLicenceSpan{
  width: 100%;
  margin: 10px auto;
  background: #80808029;
  padding: 15px;
  font-weight: 600;
  color: #202529;

}
.modspanRating{
  text-align: center;
  box-shadow: none !important;
  background: #ebebeb;
  font-size: 15px;
}
.profileheddingclsMatch{
  width: 120px !important;
}
.tabs-view.tabs-width {
  width: max-content;
  margin: 0 10px;
}
.new-drop{
  padding: 0 !important;
  margin-right: 10px !important;
  margin-left: 0 !important;
  height: 38px !important;
}
.new-drop button {
  height: 38px !important;
}
.header-cross{
  background: #ffffff;
  padding: 6px;
  font-size: 8px;
  border-radius: 50px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b52424;
  cursor: pointer;
  margin-top: -1px;
}
.dropdown-menu.w-100.set-text-m.show {
  min-width: 14rem !important;
}

@media(min-width:992px){
  .addressWidthSpace{
    width: 270px;
  }
}

.staff-tags{
  span:nth-child(1){
    margin-left: 0 !important;
    @media(max-width:575px){
      margin-left: 0.25rem !important;
    }
  }
}